var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" Generated Bills "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.form.items.data,"search":_vm.search,"options":_vm.pagination,"footer-props":_vm.footerProps,"server-items-length":_vm.form.items.meta.total,"loading":_vm.form.loading,"expanded":_vm.expanded,"item-key":"billing"},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',[_vm._v(_vm._s(index + _vm.form.items.meta.from))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.billing))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.month_name))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.generate_on))]),_c('td',[_c('v-btn',{on:{"click":function($event){return expand(!isExpanded)}}},[_vm._v(_vm._s(isExpanded ? "Close" : "Expand")+" ")])],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.billing_logs.length > 0)?_c('span',{staticStyle:{"width":"100%"}},[_c('table',{attrs:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-xs-left"},[_c('strong',[_vm._v("Grade")])]),_c('th',{staticClass:"text-xs-left"},[_c('strong',[_vm._v("Sync Status")])]),_c('th',{staticClass:"text-xs-left"},[_c('strong',[_vm._v("Action")])])]),_vm._l((item.billing_logs),function(x,key){return _c('tr',{key:key,staticStyle:{"height":"0px"}},[_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(x.grade))]),_c('td',{staticClass:"text-xs-left",staticStyle:{"color":"white"}},[(x.is_journal_entry == 0)?_c('span',[_c('v-chip',{attrs:{"color":"primary","text-color":"white"}},[_vm._v("Billing On Processing")])],1):_vm._e(),(x.is_journal_entry == 1)?_c('span',[_c('v-chip',{attrs:{"color":"warning","text-color":"white"}},[_vm._v("Waiting for approval")])],1):_vm._e(),(x.is_journal_entry == 2)?_c('span',[_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v("Approved")])],1):_vm._e()]),_c('td',[_c('v-btn',{staticClass:"pa-0 ma-1",attrs:{"slot":"activator","small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.viewDetail(x, item)}},slot:"activator"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("crop_free")])],1)],1)])})],2)])]):_c('span',{staticStyle:{"margin-left":"30px"}},[_vm._v(" "+_vm._s(_vm.props.item.billing_logs)+" No Bill generated ")])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v(" Your search for \""+_vm._s(_vm.search)+"\" found no results. ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }