<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Generated Bills
            <v-spacer></v-spacer>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :search="search"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            :expanded.sync="expanded"
            item-key="billing"
          >
            <template v-slot:item="{ index, item, expand, isExpanded }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.billing }}</td>
                <td class="text-xs-left">{{ item.month_name }}</td>
                <td class="text-xs-left">{{ item.generate_on }}</td>
                <td>
                  <v-btn @click="expand(!isExpanded)"
                    >{{ isExpanded ? "Close" : "Expand" }}
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <span v-if="item.billing_logs.length > 0" style="width: 100%">
                <table width="100%">
                  <thead>
                    <tr>
                      <th class="text-xs-left"><strong>Grade</strong></th>
                      <th class="text-xs-left"><strong>Sync Status</strong></th>
                      <th class="text-xs-left"><strong>Action</strong></th>
                    </tr>
                    <tr
                      v-for="(x, key) in item.billing_logs"
                      :key="key"
                      style="height: 0px"
                    >
                      <td class="text-xs-left">{{ x.grade }}</td>
                      <td class="text-xs-left" style="color: white">
                        <span v-if="x.is_journal_entry == 0">
                          <v-chip color="primary" text-color="white"
                            >Billing On Processing</v-chip
                          >
                        </span>
                        <span v-if="x.is_journal_entry == 1">
                          <v-chip color="warning" text-color="white"
                            >Waiting for approval</v-chip
                          >
                        </span>
                        <span v-if="x.is_journal_entry == 2">
                          <v-chip color="green" text-color="white"
                            >Approved</v-chip
                          >
                        </span>
                      </td>
                      <td>
                        <v-btn
                          small
                          icon
                          color="primary"
                          class="pa-0 ma-1"
                          slot="activator"
                          @click="viewDetail(x, item)"
                        >
                          <v-icon small>crop_free</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </thead>
                </table>
              </span>
              <span v-else style="margin-left: 30px">
                {{ props.item.billing_logs }}
                No Bill generated
              </span>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

const NepaliDate = require("nepali-date");
const nd = new NepaliDate();

export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        sod_date: nd.format("YYYY-MM-DD"),
      },
      "/api/billing-month"
    ),

    search: null,
    expanded: [],
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      { text: "Billing", align: "left", value: "billing" },
      { text: "Month", align: "left", value: "month" },
      { text: "Generated On", align: "left", value: "generate_on" },
    ],
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function() {
      this.get();
    },
    batch: function(value) {
      this.get();
    },
  },

  methods: {
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },
    viewDetail(billingLog, item) {
      this.$router.push({
        path: "billing-to-account/" + billingLog.id,
        query: {
          billLogId: billingLog.id,
          grade: billingLog.grade,
          billing: item.billing,
          billing_month: item.month_name,
          is_journal_entry: billingLog.is_journal_entry,
        },
      });
    },
  },
};
</script>
<style lang="scss">
.infoNotice {
  margin-top: -33px !important;
  position: absolute !important;
  border-bottom: none !important;
}

.infoNotice:hover {
  background: none !important;
}
</style>
